<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="container">
      <div class="column spacer">
        <h1>Cloud Server / VPS Hosting, stabiel en flexibel</h1>
        <h2>Stel je eigen VPS samen</h2>
        <Prices :items="data.staticData.prices.cloudServersPrices"/>
      </div>
    </div>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <div>
          <h2>
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/dozen-e1448658458389.png"
              height="154"
              width="254"
              title="vps hosting"
              alt="vps hosting"
            >
            Cloud Server Hosting of VPS Hosting
          </h2>
          <p>Cloud Server Hosting of een VPS: wat zijn de verschillen? Het is een vraag die we regelmatig van onze klanten krijgen. Het eenvoudige antwoord: u hoeft niet te kiezen. De cloud servers vormen een oplossing voor VPS hosting, waarbij we er met hoge technische standaarden voor zorgen dat u kunt rekenen op de hoogst mogelijke snelheden, de meeste opslagcapaciteit en de hoogst mogelijke betrouwbaarheid.</p>
          <p>We zijn gespecialiseerd in Cloud Server Hosting en VPS Hosting, op basis van een aantal professionele pakketten. Dat betekent dat u al vanaf €50 gebruik kunt maken van deze technisch hoogwaardige oplossing. Op basis van 2.000MB RAM, 200GB HDD-opslagruimte, 2.000GB traffic per maand en 2 CPU cores. Dit valt uit te breiden tot het meest uitgebreide pakket met 10 CPU cores, 10.000GB traffic per maand, 1.000GB HDD-opslagruimte en 10.0000MB RAM. Bovendien kunnen we maatwerk oplossingen bieden voor VPS Hosting op basis van een Cloud Server, zodat u daar gebruik van kunt maken op de manier die u wenst.</p>
        </div>
        <h3>VPS hosting met hoge technische standaarden</h3>
        <p>We gebruiken Cloud Server Hosting voor onze VPS-diensten. Dat betekent dat u kunt rekenen op een eigen virtuele omgeving, waarin we een aantal processoren, een bepaalde hoeveelheid geheugen en schijfruimte toekennen. U krijgt de garantie dat u daar gebruik van kunt maken, onafhankelijk van wat anderen op de server doen.</p>
        <p>We hanteren de hoogst mogelijke technische standaarden voor Cloud Server Hosting en VPS Hosting, zodat u geen last heeft van allerlei veiligheidsrisico’s. We voorkomen spam, virussen en malware en zorgen er daarnaast voor dat u kunt rekenen op de hoogst mogelijke betrouwbaarheid van de servers en de rest van de techniek. Op die manier garanderen we de verschillende pakketten op basis van de hoogst mogelijke kwaliteit, zodat u daar in een professionele omgeving gebruik van kunt maken.</p>
        <h3>De voordelen van Hosting on Demand</h3>
        <ul>
          <li>Support op alle niveaus</li>
          <li>Managed Cloud Servers met een SLA</li>
          <li>24/7 monitoring van Cloud Servers voor VPS Hosting</li>
        </ul>
        <p>We bieden de mogelijkheid tot maatwerk en koppelingen, zodat u de Cloud Servers voor VPS Hosting kunt inzetten binnen de organisatie op de manier zoals u dat het best uitkomt. Dat betekent dat we de servers onderling aan elkaar kunnen koppelen of een koppeling met andere bedrijfssystemen kunnen realiseren.</p>
        <p>Neem contact met ons op voor meer informatie over de mogelijkheden tot maatwerk. We lichten u graag toe hoe andere klanten gebruik maken van onze Cloud Server Hosting voor VPS Hosting. Daarnaast kunnen we op basis van specifieke doelstellingen en vragen aangeven welke technische oplossingen daarvoor beschikbaar zijn. Bovendien kunnen we u meer vertellen over de A-merk apparatuur waarmee we werken en hoe we de hoge betrouwbaarheid voor uw VPS en Cloud Servers kunnen garanderen. Neem contact met ons op, we horen graag van u.</p>
      </div>
      <div class="column right-block small-block">
        <div class="green-block">
          <h3>Uw Cloud Server bij Hosting On Demand</h3>
          <ul>
            <li>99,9% uptime</li>
            <li>SLA en support inbegrepen</li>
            <li>Onze support gaat verder</li>
            <li>Onze VSP Serrvers zijn altijd managed</li>
          </ul>
        </div>
        <div class="green-block">
          <h3>Veel gestelde vragen over vps</h3>
          <ExpansionPanels
            :panels="questions.map(q => q.title)"
          >
            <template
              v-for="(question, index) in questions"
              :key="index"
              v-slot:[questionSlotName(index)]
            >
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Prices from '@/components/Prices.vue';
import ExpansionPanels from '@/components/ExpansionPanels.vue';

export default {
  name: 'CloudServers',
  components: {
    Breadcrumbs,
    Prices,
    ExpansionPanels,
  },
  setup() {
    const data = inject('data');
    const questions = data.staticData.questions.filter(q => q.tags.split(' ').includes('cloud-servers'));

    function questionSlotName(index) {
      return `content-text-${index}`;
    }

    return {
      // store
      data,

      // static data
      questions,

      // methods
      questionSlotName,
    };
  },
}
</script>