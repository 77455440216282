<template>
  <div
    class="prices"
    :class="[background ? 'background' : '']"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="price-block"
    >
      <div
        v-if="item.title"
        class="price-block-title"
      >
        {{ item.title }}
      </div>
      <div
        v-if="item.price && item.price.value"
        class="price-block-price"
      >
        <span v-if="item.price.prepend">
          {{ item.price.prepend }}
        </span>
        {{ item.price.value }}
        <span v-if="item.price.append">
          {{ item.price.append }}
        </span>
      </div>
      <div
        v-if="item.properties && item.properties.length"
        class="price-block-properties"
      >
        <div
          v-for="(property, index) in item.properties"
          :key="index"
          class="price-block-property"
        >
          <div
            class="price-block-property-icon glyphicon"
            :class="[`glyphicon-${property.icon}`]"
          ></div>
          <div>{{ property.text }}</div>
        </div>
      </div>
      <a
        v-if="item.link && item.linkText && item.link.startsWith('http')"
        class="price-block-link"
        :href="item.link"
      >
        {{ item.linkText }}
      </a>
      <router-link
        v-else-if="item.link && item.linkText"
        class="price-block-link"
        :to="item.link"
      >
        {{ item.linkText }}
      </router-link>
      <a
        v-if="item.buttonLink && item.buttonLinkText"
        class="button price-block-button-link"
        :href="item.buttonLink"
      >
        {{ item.buttonLinkText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prices',
  props: {
    background: Boolean,
    items: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.prices {
  margin-bottom: 20px;

  .price-block {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: var(--primary-light);
    border: 1px solid var(--border);
    transition: all 0.2s ease-in;

    .price-block-title {
      margin: 20px -1px;
      padding: 20px;
      background-color: var(--primary-dark);
      color: var(--contrast-text);
      font-size: 18px;
      font-family: 'Roboto Slab', serif;
      font-weight: 300;
      line-height: 1.1;
    }

    .price-block-price {
      min-height: 62px;
      margin: 0 -1px 20px -1px;
      background-color: var(--primary);
      color: var(--contrast-text);
      font-size: 40px;
      text-align: center;

      span {
        font-size: 12px;
      }
    }

    .price-block-properties {
      margin-bottom: 10px;
      padding-left: 40px;
      display: flex;
      flex-direction: column;

      .price-block-property {
        display: flex;
        font-size: 14px;

        .price-block-property-icon {
          margin-right: 10px;
        }
      }
      .price-block-property:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .price-block-link {
      flex: 1;
      padding: 10px 20px;
      color: var(--primary-dark);
      line-height: 30px;
    }
    .price-block-link:hover {
      color: var(--primary);
    }

    .price-block-button-link {
      width: 100%;
      margin: 20px 0;
      padding: 10px 0;
    }
  }
  .price-block:hover {
    box-shadow: 3px 5px 7px rgb(0 0 0 / 70%);
    transform: scale(1.1);

    .price-block-title {
      background-color: var(--highlight);
    }

    .price-block-price  {
      background-color: var(--highlight);
    }
  }

  &.background {
    padding: 20px;
    background-color: var(--primary-light);
  }
}
</style>